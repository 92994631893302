import React from 'react';
import PropTypes from 'prop-types';
import {
    Drawer,
    Paragraph,
    Button,
    ButtonToolbar,
    Form,
    FormControl,
    FormGroup,
    ControlLabel,
    HelpBlock,
    DatePicker,
    InputPicker,
    InputGroup,
    InputNumber

} from 'rsuite';
import RFormControl from '../../componentsGeneral/Rsuite/Form/RFormControl';
import { lettersNumbersDashesInputMask, numbersInputMask, lettersNumbersDashesSpacesInputMask } from '../../../resources/logic/inputMasks';

const styles = {
    // width: 300,
    marginBottom: 10,
    width: '100%'
};

const mountRef = React.createRef();

const AddNewMasterDrawer = ({ onChangeAddNewMaster, openAddNewCarrierModal, show, placement, confirm, close, state, ...props }) => (

    <Drawer show={state.showAddNewMasterDrawer} onHide={close} placement={placement} style={props.isMobile ? { width: '350px' } : { width: '650px' }}    >
        <Drawer.Header>
            <Drawer.Title>Add New Master</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body> <div ref={mountRef}>

            <Form fluid>

                <FormGroup>
                    <ControlLabel>Master</ControlLabel>
                    <FormControl readOnly name="Master" value={state.Master} onChange={(e) => { onChangeAddNewMaster(e, 'Master') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                </FormGroup>

                <FormGroup>

                    <ControlLabel>Carrier</ControlLabel>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* <FormControl name="CarrierName" value={state.CarrierName} onChange={(e) => {onChangeAddNewMaster(e,'CarrierName')}}  onInput={(e) =>  e.target.value = lettersNumbersDashesSpacesInputMask(e)} /> */}
                        <InputPicker preventOverflow
                            container={() => mountRef.current}
                            // creatable
                            placeholder={'Select...'}
                            value={state.CarrierName}
                            onChange={(e) => { onChangeAddNewMaster(e, 'CarrierName') }}
                            data={props.MawbCarrierData}
                            block
                            cleanable={true}
                            style={{ flex: 1 }}
                        />

                        <Button onClick={openAddNewCarrierModal} style={{ marginLeft: '10px' }}>Add New Carrier</Button>
                    </div>
                </FormGroup>








                <FormGroup>
                    <ControlLabel>Flight Voyage</ControlLabel>
                    <FormControl name="FlightVoyage" value={state.FlightVoyage} onChange={(e) => { onChangeAddNewMaster(e, 'FlightVoyage') }} onInput={(e) => e.target.value = lettersNumbersDashesInputMask(e)} />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>ETA Discharge</ControlLabel>
                    <DatePicker
                        container={() => mountRef.current} oneTap style={styles} placeholder="ETA Discharge" value={state.MinETADischarge} onChange={(e) => { onChangeAddNewMaster(e, 'MinETADischarge') }} />
                </FormGroup>

                <FormGroup >
                    <ControlLabel>Expected Cartons:</ControlLabel>
                    <InputNumber style={styles} defaultValue={0} className={'custom-input-number'} onChange={(e) => onChangeAddNewMaster(e, 'ExpectedCartons')} scrollable={false} onInput={(e) => e.target.value = numbersInputMask(e)} />
                </FormGroup>

                <FormGroup>
                    <ButtonToolbar>
                        <Button onClick={confirm} appearance="primary">Submit</Button>
                        <Button onClick={close} appearance="default">Cancel</Button>
                    </ButtonToolbar>
                </FormGroup>
            </Form>


        </div> </Drawer.Body>
        <Drawer.Footer>
            <br />

        </Drawer.Footer>


    </Drawer>

);


// AddNewMasterDrawer.propTypes = {
//     isMobile:           PropTypes.bool,
//     placement:          PropTypes.string,
//     show:               PropTypes.bool,
//     confirm:              PropTypes.func,
//     close:              PropTypes.func,
//     addCompanyChangeHandler:              PropTypes.func,

//     companyid:            PropTypes.string,
//     companyname:          PropTypes.string,
//     active:                 PropTypes.number,
//     contactname:            PropTypes.string,
//     phone:                  PropTypes.string,
//     email:                  PropTypes.string,
//     addressline1:           PropTypes.string,
//     addressline2:           PropTypes.string,
//     city:                   PropTypes.string,
//     state:                  PropTypes.string,
//     zip:                    PropTypes.string,
//     country:                PropTypes.string,
//     website:                PropTypes.string
// };


AddNewMasterDrawer.defaultProps = {
    show: false
};

export default AddNewMasterDrawer;




// <FormGroup>
// <ControlLabel>Total Cartons</ControlLabel>
// <FormControl name="TotCartons" value={state.TotCartons} onChange={(e) => {onChangeAddNewMaster(e,'TotCartons')}}  onInput={(e) =>  e.target.value = numbersInputMask(e)} />
// </FormGroup>          

// <FormGroup>
// <ControlLabel>Total Weight</ControlLabel>
// <FormControl name="TotWeight" value={state.TotWeight} onChange={(e) => {onChangeAddNewMaster(e,'TotWeight')}}  onInput={(e) =>  e.target.value = numbersInputMask(e)} />
// </FormGroup>   

// <FormGroup>
// <ControlLabel>Total Chargeable</ControlLabel>
// <FormControl name="TotChargeable" value={state.TotChargeable} onChange={(e) => {onChangeAddNewMaster(e,'TotChargeable')}}  onInput={(e) =>  e.target.value = numbersInputMask(e)} />
// </FormGroup>   


// <FormGroup>
// <ControlLabel>ETA Discharge</ControlLabel>
// <DatePicker
// container = {() => mountRef.current} oneTap style = { styles } placeholder = "MinETADischarge" value = { state.MinETADischarge }  onChange = {(e) => { onChangeAddNewMaster(e, 'MinETADischarge') }}/>
// {/* <FormControl name="MinETADischarge" value={state.MinETADischarge} onChange={(e) => {onChangeAddNewMaster(e,'MinETADischarge')}}  onInput={(e) =>  e.target.value = numbersInputMask(e)} /> */}
// </FormGroup>   