import { connect } from 'react-redux';
import React, { Component } from 'react';
import Warehouse from './Warehouse'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../redux/actions/vasActions';
import { queryStringParse, formatDateForAPI, formatStringForAPI } from '../../../resources/logic/functions';

const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isHawbLoading: store.vasReducer.isHawbLoading,
    HawbData: store.vasReducer.HawbData,

    isHawbT86Loading: store.vasReducer.isHawbT86Loading,
    HawbT86Data: store.vasReducer.HawbT86Data,

    isHawbConsigneeLoading: store.vasReducer.isHawbConsigneeLoading,
    HawbConsigneeData: store.vasReducer.HawbConsigneeData,

    isHawbDestinationLoading: store.vasReducer.isHawbDestinationLoading,
    HawbDestinationData: store.vasReducer.HawbDestinationData,

    PostLoadSuccessful: store.vasReducer.PostLoadSuccessful,

    HawbRecoverySaveSuccessful: store.vasReducer.HawbRecoverySaveSuccessful,


    isGetCustomsCityAPILoading: store.vasReducer.isGetCustomsCityAPILoading,
    GetCustomsCityAPISuccessful: store.vasReducer.GetCustomsCityAPISuccessful,



    isXdockHawbT86ListGetLoading: store.vasReducer.isXdockHawbT86ListGetLoading,
    XdockHawbT86ListData: store.vasReducer.XdockHawbT86ListData,


});

const mapDispatchToProps = dispatch =>
({

    hawbGet: (_email, _authToken, _HouseID, _HouseRef, _MasterID, _Master, _ShipmentETDStart, _ShipmentETDEnd, _Origin, _LastDischargePort, _ETADischargeStart, _ETADischargeEnd, _EstCartageDeliveryStart, _EstCartageDeliveryEnd, _ActualCartageDeliveryStart, _ActualCartageDeliveryEnd, _HawbStatusID, _ConsigneeID, _Destination, _WarehouseID, _WarehousePort, _OrderNo, _CustomerID, _CustomsCategoryID) => {
        dispatch(actions.hawbGet(_email, _authToken, _HouseID, _HouseRef, _MasterID, _Master, _ShipmentETDStart, _ShipmentETDEnd, _Origin, _LastDischargePort, _ETADischargeStart, _ETADischargeEnd, _EstCartageDeliveryStart, _EstCartageDeliveryEnd, _ActualCartageDeliveryStart, _ActualCartageDeliveryEnd, _HawbStatusID, _ConsigneeID, _Destination, _WarehouseID, _WarehousePort, _OrderNo, _CustomerID, _CustomsCategoryID))
    },
    hawbT86Get: (_email, _authToken, _HouseID, _HouseRef, _MasterID, _Master, _ShipmentETDStart, _ShipmentETDEnd, _Origin, _LastDischargePort, _ETADischargeStart, _ETADischargeEnd, _EstCartageDeliveryStart, _EstCartageDeliveryEnd, _ActualCartageDeliveryStart, _ActualCartageDeliveryEnd, _HawbStatusID, _ConsigneeID, _Destination, _WarehouseID, _WarehousePort, _OrderNo, _CustomerID) => {
        dispatch(actions.hawbT86Get(_email, _authToken, _HouseID, _HouseRef, _MasterID, _Master, _ShipmentETDStart, _ShipmentETDEnd, _Origin, _LastDischargePort, _ETADischargeStart, _ETADischargeEnd, _EstCartageDeliveryStart, _EstCartageDeliveryEnd, _ActualCartageDeliveryStart, _ActualCartageDeliveryEnd, _HawbStatusID, _ConsigneeID, _Destination, _WarehouseID, _WarehousePort, _OrderNo, _CustomerID))
    },
    hawbConsigneeGet: (_email, _authToken, _WarehouseID, _CustomerID) => {
        dispatch(actions.hawbConsigneeGet(_email, _authToken, _WarehouseID, _CustomerID))
    },

    hawbDestinationGet: (_email, _authToken, _HawbStatusID) => {
        dispatch(actions.hawbDestinationGet(_email, _authToken, _HawbStatusID))
    },

    loadPost: (_email, _authToken, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalPCS, _TotalPallets, _LoadStatusID, _LoadHawbData, _HouseID, _PCS, _Pallets, _WarehouseID, _CustomerID, _MC, _DOT, _Driver, _Truck, _TruckPlate, _TrailerPlate, _Notes) => {
        dispatch(actions.loadPost(_email, _authToken, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalPCS, _TotalPallets, _LoadStatusID, _LoadHawbData, _HouseID, _PCS, _Pallets, _WarehouseID, _CustomerID, _MC, _DOT, _Driver, _Truck, _TruckPlate, _TrailerPlate, _Notes))
    },
    hawbRecoveryPost: (_email, _authToken, _MasterID, _HouseID, _HouseRef, _RecoveredPCS, _Pallets, _Notes, _CustomsCleared, _WarehouseID, _CustomerID) => {
        dispatch(actions.hawbRecoveryPost(_email, _authToken, _MasterID, _HouseID, _HouseRef, _RecoveredPCS, _Pallets, _Notes, _CustomsCleared, _WarehouseID, _CustomerID))
    },

    customsCityAPIMessages: (_email, _authToken, _paramHouse, _paramMaster, _getOnly) => {
        dispatch(actions.customsCityAPIMessages(_email, _authToken, _paramHouse, _paramMaster, _getOnly))
    },


    xdockHawbT86ListGet: (_email, _authToken, _WarehouseID, _CustomerID, _MawbList) => {
        dispatch(actions.xdockHawbT86ListGet(_email, _authToken, _WarehouseID, _CustomerID, _MawbList))
    },

    

});

class WarehouseContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
        // this.props.userWarehousesGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, null )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }


        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }

        if (prevProps.PostLoadSuccessful !== this.props.PostLoadSuccessful && this.props.PostLoadSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }

        if (prevProps.HawbRecoverySaveSuccessful !== this.props.HawbRecoverySaveSuccessful && this.props.HawbRecoverySaveSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }
        else {
        }

        if (prevProps.GetCustomsCityAPISuccessful !== this.props.GetCustomsCityAPISuccessful && this.props.GetCustomsCityAPISuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 10000);
        }
        else {
        }


    }



    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try {
            var paramHouseID = queryStringParse(values.houseid)
        }
        catch (e) {
            var paramHouseID = null
        }
        try {
            var paramHouseRef = formatStringForAPI(values.houseref)
        }
        catch (e) {
            var paramHouseRef = null
        }
        try {
            var paramMasterID = queryStringParse(values.masterid)
        }
        catch (e) {
            var paramMasterID = null
        }
        try {
            var paramMaster = formatStringForAPI(values.master)
        }
        catch (e) {
            var paramMaster = null
        }
        try {
            var paramOrderNo = formatStringForAPI(values.orderno)
        }
        catch (e) {
            var paramOrderNo = null
        }
        try {
            var paramShipmentETDStart = formatDateForAPI(values.shipmentetdstart)
        }
        catch (e) {
            var paramShipmentETDStart = null
        }
        try {
            var paramShipmentETDEnd = formatDateForAPI(values.shipmentetdend)
        }
        catch (e) {
            var paramShipmentETDEnd = null
        }
        try {
            var paramOrigin = queryStringParse(values.origin)
        }
        catch (e) {
            var paramOrigin = null
        }
        try {
            var paramLastDischargePort = queryStringParse(values.lastdischargeport)
        }
        catch (e) {
            var paramLastDischargePort = null
        }
        try {
            var paramETADischargeStart = formatDateForAPI(values.etadischargestart)
        }
        catch (e) {
            var paramETADischargeStart = null
        }
        try {
            var paramETADischargeEnd = formatDateForAPI(values.etadischargeend)
        }
        catch (e) {
            var paramETADischargeEnd = null
        }
        try {
            var paramEstCartageDeliveryStart = formatDateForAPI(values.estcartagedeliverystart)
        }
        catch (e) {
            var paramEstCartageDeliveryStart = null
        }
        try {
            var paramEstCartageDeliveryEnd = formatDateForAPI(values.estcartagedeliveryend)
        }
        catch (e) {
            var paramEstCartageDeliveryEnd = null
        }
        try {
            var paramActualCartageDeliveryStart = formatDateForAPI(values.actualcartagedeliverystart)
        }
        catch (e) {
            var paramActualCartageDeliveryStart = null
        }
        try {
            var paramActualCartageDeliveryEnd = formatDateForAPI(values.actualcartagedeliveryend)
        }
        catch (e) {
            var paramActualCartageDeliveryEnd = null
        }
        try {
            var paramHawbStatusID = queryStringParse(values.hawbstatusid)
        }
        catch (e) {
            var paramHawbStatusID = null
        }
        try {
            var paramConsignee = queryStringParse(values.consignee)
        }
        catch (e) {
            var paramConsignee = null
        }
        try {
            var paramDestination = queryStringParse(values.destination)
        }
        catch (e) {
            var paramDestination = null
        }

        try {
            var paramWarehousePort = queryStringParse(values.warehouseport)
        }
        catch (e) {
            var paramWarehousePort = null
        }


        // try {
        //     var paramWarehouseID = queryStringParse(values.warehouseid)
        // }
        // catch (e) {
        //     var paramWarehouseID = null
        // }


        this.props.hawbConsigneeGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.warehouseSelection, this.props.customerSelection)
        this.props.hawbDestinationGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramHawbStatusID)


        // this.props.hawbGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken,
        //     paramHouseID,
        //     paramHouseRef,
        //     paramMasterID,
        //     paramMaster,
        //     paramShipmentETDStart,
        //     paramShipmentETDEnd,
        //     paramOrigin,
        //     paramLastDischargePort,
        //     paramETADischargeStart,
        //     paramETADischargeEnd,
        //     paramEstCartageDeliveryStart,
        //     paramEstCartageDeliveryEnd,
        //     paramActualCartageDeliveryStart,
        //     paramActualCartageDeliveryEnd,
        //     paramHawbStatusID,
        //     paramConsignee,
        //     paramDestination,
        //     this.props.warehouseSelection,
        //     paramWarehousePort,
        //     paramOrderNo,
        //     this.props.customerSelection,
        //     null
        //     );



        this.props.hawbT86Get(this.props.currentUserEmail, this.props.userSignInData.AuthToken,
            paramHouseID,
            paramHouseRef,
            paramMasterID,
            paramMaster,
            paramShipmentETDStart,
            paramShipmentETDEnd,
            paramOrigin,
            paramLastDischargePort,
            paramETADischargeStart,
            paramETADischargeEnd,
            paramEstCartageDeliveryStart,
            paramEstCartageDeliveryEnd,
            paramActualCartageDeliveryStart,
            paramActualCartageDeliveryEnd,
            paramHawbStatusID,
            paramConsignee,
            paramDestination,
            this.props.warehouseSelection,
            paramWarehousePort,
            paramOrderNo,
            this.props.customerSelection
        );
    }



    render() {

        return (

            <Warehouse
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isHawbLoading={this.props.isHawbLoading}
                HawbData={this.props.HawbData}


                isHawbT86Loading={this.props.isHawbT86Loading}
                HawbT86Data={this.props.HawbT86Data}

                isHawbConsigneeLoading={this.props.isHawbConsigneeLoading}
                HawbConsigneeData={this.props.HawbConsigneeData}

                isHawbDestinationLoading={this.props.isHawbDestinationLoading}
                HawbDestinationData={this.props.HawbDestinationData}

                loadPost={this.props.loadPost}
                PostLoadSuccessful={this.props.PostLoadSuccessful}

                hawbRecoveryPost={this.props.hawbRecoveryPost}
                HawbRecoverySaveSuccessful={this.props.HawbRecoverySaveSuccessful}

                customsCityAPIMessages={this.props.customsCityAPIMessages}
                isGetCustomsCityAPILoading={this.props.isGetCustomsCityAPILoading}


                xdockHawbT86ListGet={this.props.xdockHawbT86ListGet}
                isXdockHawbT86ListGetLoading={this.props.isXdockHawbT86ListGetLoading}
                XdockHawbT86ListData={this.props.XdockHawbT86ListData}


            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WarehouseContainer));



