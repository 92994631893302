export function limitCharacterLength(input, length) {
  try {
    var limitlength = input.substring(0, length);
    return limitlength
  }
  catch (e) {
    return input
  }
}

export default function upperCaseMask(e) {
  return ("" + e.target.value).toUpperCase()
}

// export function noSpecialCharactersMask(e){
//     return e.target.value.trim().replace(/[|&;$%"'` #!^*~<>()+,=]/g,'')
// }

export function lettersNumbersInputMask(e) {
  return e.target.value.trim().replace(/[^A-Za-z0-9]/gi, '')
}

// export function lettersNumbersDashesInputMask(e) {
//   return e.target.value.trim().replace(/[^A-Za-z0-9_-]/gi, '')
// }

// export function lettersNumbersDashesInputMask(e) {
//   return e.target.value.trim().replace(/[^A-Za-z0-9_*-]/gi, '')
// }

export function lettersNumbersDashesInputMask(e) {
  return e.target.value.trim().replace(/[^A-Za-z0-9_*-/]/gi, '')
}



export function numbersInputMaskNullToZero(e) {
  let value = e.target.value.trim();

  // Replace non-numeric characters except for the minus sign
  value = value.replace(/[^0-9-]/gi, '');

  // Ensure only one minus sign at the beginning
  if (value.indexOf('-') > 0) {
    value = value.replace(/-/g, '');
  }

  // Remove leading zeros
  value = value.replace(/^0+(?!$)/, '');

  // Replace null or empty value with 0
  if (value === '' || value === '-') {
    value = '0';
  }

  return value;
}


export function numbersDashInputMask(e) {
  return e.target.value.trim().replace(/[^0-9-]/gi, '')
}

export function numbersInputMask(e) {
  return e.target.value.trim().replace(/[^0-9]/gi, '')
}

// export function positiveIntegerInputMask(e) {
//   let value = e.target.value.trim().replace(/[^0-9]/gi, '');
//   let intValue = parseInt(value, 10);
//   return intValue > 0 ? intValue : 1;
// }

export function positiveIntegerInputMask(e) {
  let value = e.target.value.trim().replace(/[^0-9]/gi, '');
  let intValue = parseInt(value, 10);
  return intValue > 0 ? intValue : '';
}


export function lettersNumbersDashesSpacesInputMask(e) {
  // return e.target.value.replace(/[^A-Za-z0-9_-\s]/gi, '')
  return e.target.value.replace(/[^A-Za-z0-9_*-\s]/gi, '')
}

export function lettersNumbersDashesSpacesCommasInputMask(e) {
  return e.target.value.replace(/[^A-Za-z0-9_-\s,]/gi, '')
}

export function textStringInputMask(e) {
  try {
    return e.target.value.replace(/[^A-Za-z0-9_-\s,.#]/gi, '')
  }
  catch (e) {
    return e
  }
}

export function tenthsDecimalInputMask(e) {
  // return e.target.value.trim().replace(/^[0-9]{1,6}(\\,\\d{1,2})?$/gi, '')
  // return e.target.value.trim().replace(/(\d)(?=(\d{3})+(?!\d))/g,'$1.')
  // return e.target.value.trim().replace(/^[0-9]*[\.|\,]{0,1}[0-9]{0,2}?$/gi, '')

  return e.target.value.trim()
    .replace(/[^\d.]/g, '')
    // .replace(new RegExp("(^[\\d]{" + beforeDecimal + "})[\\d]", "g"), '$1') 
    .replace(/(\..*)\./g, '$1')
    .replace(new RegExp("(\\.[\\d]{1}).", "g"), '$1');


  // /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;

  // const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/gi;
  // return e.target.value.match(regex)[0];
}



// export function currencyInputMask(e) {

//   const rawValue = e.target.value;

//   // Remove all non-digit characters except the decimal point
//   const cleanValue = rawValue.replace(/[^0-9.]/g, '');

//   // Split the value into dollars and cents
//   const parts = cleanValue.split('.');
//   let dollars = parts[0] || '';
//   let cents = parts[1] || '';

//   var centsExist = false
//   if (typeof (parts[1]) !== 'undefined') {
//     centsExist = true
//   }

//   // Ensure cents have at most two decimal places
//   cents = cents.slice(0, 2);

//   // // Add commas for thousands separators in dollars part
//   // dollars = dollars.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

//   // Combine dollars and cents with the decimal point
//   const formattedValue = centsExist ? `${dollars}.${cents}` : dollars;

//   return formattedValue

//   //   const rawValue = e.target.value;

//   //   // Remove all non-digit characters except the decimal point
//   //   const cleanValue = rawValue.replace(/[^0-9.]/g, '');

//   //   // Split the value into dollars and cents
//   //   const parts = cleanValue.split('.');
//   //   let dollars = parts[0] || '';
//   //   let cents = parts[1] || '';


//   //   var centsExist = false
//   //   if (typeof(parts[1] ) !== 'undefined'){
//   //     centsExist = true
//   //   }


//   //   // Ensure cents have at most two decimal places
//   //   cents = cents.slice(0, 2);

//   //   // // Add commas for thousands separators in dollars part
//   //   // dollars = dollars.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

//   //   // Combine dollars and cents with the decimal point
//   //   const formattedValue = centsExist ? `${dollars}.${cents}` : dollars;

//   //   return formattedValue



//   // return e.target.value
//   // .replace(/[^\d.]/g, '')
//   // // .replace(new RegExp("(^[\\d]{" + beforeDecimal + "})[\\d]", "g"), '$1') 
//   // .replace(/(\..*)\./g, '$1')
//   // .replace(new RegExp("(\\.[\\d]{2}).", "g"), '$1');


//   // ^(10|\d)(\.\d{1,2})?$

//   // const sanitizedInput = e.target.value.replace(/[^(10|\d)(\.\d{1,2})]/gi, '');

//   // return sanitizedInput


// }


export function hundredthsDecimalInputMask(e) {
  // return e.target.value.trim().replace(/^[0-9]{1,6}(\\,\\d{1,2})?$/gi, '')
  // return e.target.value.trim().replace(/(\d)(?=(\d{3})+(?!\d))/g,'$1.')
  // return e.target.value.trim().replace(/^[0-9]*[\.|\,]{0,1}[0-9]{0,2}?$/gi, '')

  return e.target.value.trim()
    .replace(/[^\d.]/g, '')
    // .replace(new RegExp("(^[\\d]{" + beforeDecimal + "})[\\d]", "g"), '$1') 
    .replace(/(\..*)\./g, '$1')
    .replace(new RegExp("(\\.[\\d]{2}).", "g"), '$1');


  // /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;

  // const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/gi;
  // return e.target.value.match(regex)[0];
}

export function zipCodeInputMask(e) {
  // e.preventDefault();
  // get pasted content
  // let pasteText = e.clipboardData.getData("text/plain");
  // only allow integers
  var inputText = e.target.value.replace(/[^0-9]/g, "");
  // add to current input value (target)
  // let newContent = e.target.value + pasteText;
  // only allow 5 digits total
  var newContent = inputText.substring(0, 5);
  // set new value of input
  // e.target.value = newContent;
  // set new state of zipcode
  return newContent

}

// export function emailInputMask(e) {
//   // return e.target.value.replace(/^(.{2})[^@]+/, "$1XXX")
//   // return e.target.value.replace(/^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g, "")
//   return e.target.value
// }



export function phoneInputMask(e) {

  // Remove all non-digit characters from the input
  var digitsOnly = e.target.value.replace(/\D/g, '');

  var limitlength = digitsOnly.substring(0, 10);

  // Apply formatting using regex and replace
  var formattedNumber = limitlength.replace(
    /(\d{3})(\d{3})(\d{4})/,
    // '($1) $2-$3'
    '$1-$2-$3'
  );

  return formattedNumber
}



export function fourDigitNumberMask(e) {

  // Remove all non-digit characters from the input
  var digitsOnly = e.target.value.replace(/\D/g, '');

  var limitlength = digitsOnly.substring(0, 4);

  return limitlength
}



export function limitNumberLengthMask(e, length) {

  // Remove all non-digit characters from the input
  var digitsOnly = e.target.value.replace(/\D/g, '');

  var limitlength = digitsOnly.substring(0, length);

  return limitlength
}

export function limitUpperCaseLengthMask(e, length) {

  // Remove all non-digit characters from the input
  var digitsOnly = e.target.value.replace(/[^A-Za-z]/gi, '');

  var limitlength = digitsOnly.substring(0, length);

  var UpperCase = ("" + limitlength).toUpperCase()


  return UpperCase
}




export function emailInputMask(e) {
  // try {
  //   const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  //   return emailRegex.test(e.target.value);
  // }
  // catch (e) {
  //   return false
  // }



  // Remove spaces and other invalid characters
  // const sanitizedInput = e.target.value.replace(/[^\w@.-]/g, '');
  const sanitizedInput = e.target.value.replace(/[^\w@.  ! # $ % & * +  / = ? ^ _ ` { | } ~ -]/g, '');


  const removeExcessAt = sanitizedInput.replace(/@/g, (match, offset) =>
    offset === sanitizedInput.indexOf('@') ? match : ''
  );

  // Apply formatting using regex and replace
  const formattedInput = removeExcessAt.replace(
    /^([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{0,3}).*$/,
    '$1'
  );

  return formattedInput

}




export function textNumbersSymbolsInputmask(e) {
  // try {
  //   const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  //   return emailRegex.test(e.target.value);
  // }
  // catch (e) {
  //   return false
  // }



  // Remove spaces and other invalid characters
  // const sanitizedInput = e.target.value.replace(/[^\w@.-]/g, '');
  const sanitizedInput = e.target.value.replace(/[^\w@.  ! # $ % & * +  / = ? ^ _ ` : , { | } ~ -]/g, '');


  // const removeExcessAt = sanitizedInput.replace(/@/g, (match, offset) =>
  //   offset === sanitizedInput.indexOf('@') ? match : ''
  // );

  // // Apply formatting using regex and replace
  // const formattedInput = removeExcessAt.replace(
  //   /^([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{0,3}).*$/,
  //   '$1'
  // );

  return sanitizedInput

}


