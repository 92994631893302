import { appSettings } from './appSettings';


export const awsCognitoConfig = {
    cognitoLogin: {


        Auth: (
            appSettings.environment === "dev" ?
                //dev
                {
                    region: 'us-west-1',
                    IdentityPoolId: '',
                    UserPoolId: 'us-west-1_zV1n8Xot8',
                    ClientId: '5ru01nc3ct5bpt1rmoqnlnnnib'
                }
                :
                appSettings.environment === "prod" ?
                    //prod VAS-Web_App
                    {
                        region: 'us-east-1',
                        IdentityPoolId: '',
                        UserPoolId: 'us-east-1_Yu1gbDHLd',
                        ClientId: '4g6euca7j2b0vtfbpgk9beg1lk'
                    }
                    :
                    appSettings.environment === "ext" ?
                        //ext        
                        {
                            region: 'us-east-1',
                            IdentityPoolId: '',
                            UserPoolId: 'us-east-1_NL06lTlQ9',
                            ClientId: '7qhn8fr6r18dee67n2qm6b1ua0'
                        }
                        :
                        null
        ),

    },
};



