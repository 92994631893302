export const HawbDataElements = [
    {
        dBKey: 'CustomsStatusCode',
        displayName: 'Customs',
        primaryCol: true,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'HouseRef',
        displayName: 'House Ref',
        primaryCol: true,
        mobileDisplay: true,
        sortable: true,
        colWidth: 135
    },
    {
        dBKey: 'Master',
        displayName: 'Master',
        primaryCol: true,
        mobileDisplay: false,
        sortable: true,
        colWidth: 135
    },
    {
        dBKey: 'CustomerName',
        displayName: 'Customer',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 180
    },
    {
        dBKey: 'HawbStatusName',
        displayName: 'Status',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 150
    },
    {
        dBKey: 'WarehouseName',
        displayName: 'Warehouse',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },


    {
        dBKey: 'ConsigneeName',
        displayName: 'Consignee',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'DeliveryAddress',
        displayName: 'Destination',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'DCCode',
        displayName: 'DC Code',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 100
    },
    {
        dBKey: 'Cartons',
        displayName: 'Expected Cartons',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 135
    },
    {
        dBKey: 'Chargeable',
        displayName: 'Expected Weight',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 135
    },
    {
        dBKey: 'Inner',
        displayName: 'Expected Units',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 135
    },
    {
        dBKey: 'RecoveredPCS',
        displayName: 'Received CTNS',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 130
    },
    {
        dBKey: 'InWarehousePCS',
        displayName: 'In Whse PCS',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 130
    },
    {
        dBKey: 'LoadedPCS',
        displayName: 'Loaded PCS',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 130
    },

    {
        dBKey: 'Pallets',
        displayName: 'In Whse Pallets',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 130
    },
    {
        dBKey: 'LoadedPallets',
        displayName: 'Loaded Pallets',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 120
    },

    {
        dBKey: 'RecoveryNotes',
        displayName: 'Recovery Note',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },


    {
        dBKey: 'CustomsCleared',
        displayName: 'Customs Cleared',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 130
    },
    {
        dBKey: 'Orders',
        displayName: 'Orders',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 300
    },
    {
        dBKey: 'RcdDate',
        displayName: 'Rcd Date',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'InWarehouseHours',
        displayName: 'In Warehouse Hours',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },
    {
        dBKey: 'DCRequiredDate',
        displayName: 'NDC Date',
        primaryCol: false,
        mobileDisplay: false,
        sortable: true,
        colWidth: 200
    },


]


export default HawbDataElements

