import React, {
    PureComponent
} from 'react';
import AnimatedView from '../../../components/componentsGeneral/AnimatedView/AnimatedView';
import HouseDetailsT86Container from '../../../components/componentsVas/HouseDetailsT86/HouseDetailsT86Container';
import { Message } from 'rsuite';

class houseDetailsT86View extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {

    }

    render() {

        if (typeof (this.props.warehouseSelection) !== 'number' || typeof (this.props.customerSelection) !== 'number') {
            return <AnimatedView><Message style={{ marginTop: '25px' }} type="info" description="Please select only one Warehouse and one Customer to view all data." /></AnimatedView>;
        }

        return (
            <AnimatedView>
                <HouseDetailsT86Container isMobile={this.props.isMobile} warehouseSelection={this.props.warehouseSelection} customerSelection={this.props.customerSelection}/>
            </AnimatedView>
        );
    }
}

export default houseDetailsT86View;
