import React, {
    PureComponent
} from 'react';
import Loader from 'react-loader-spinner'
import { withRouter } from 'react-router-dom';
import { Row, Col, Panel, PanelGroup, Timeline, Button } from 'rsuite';
//  import {PoProductsDataElements} from '../../dataComponentsXdock/PoProductsDataElements';
//  import RTable from '../../componentsGeneral/Rsuite/Table/RTable';
import { formatBitToTrueFalse, formatNumberForAPI, formatStringForAPI } from '../../../resources/logic/functions';
import HouseDetailsLoadDataElements from '../../dataComponentsXdock/HouseDetailsLoadDataElements';
import RTable from '../../componentsGeneral/Rsuite/Table/RTable';
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import ReceiveMasterDrawer from '../Shipment/ReceiveMasterDrawer';


//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")


const tableIdCol = "HouseID"

//For Rsuite NavBar
const styles = {
    marginBottom: 50
};


class HouseDetails extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            //Receive Master Drawer
            ReceiveDict: {},
            unsavedReceiveChanges: false,
            showReceiveDrawer: false,
            ReceiveDrawerHawbID: null,
            ReceiveDrawerHouse: null,
            ReceiveDrawerNo: null,
            ReceiveDrawerPMC: null,
            ReceiveDrawerPickupBy: null,
            ReceiveDrawerTrips: null,
            ReceiveDrawerArrivalTime: null,
            ReceiveDrawerLFD: null,
            ReceiveDrawerArrivalAtWarehouse: null,
            ReceiveDrawerDeliveryTime: null,
            ReceiveDrawerNotes: null,

        };
        this.handleSortColumn = this.handleSortColumn.bind(this);
        // this.onClickEditRecovery = this.onClickEditRecovery.bind(this);
        this.onClickClearCustoms = this.onClickClearCustoms.bind(this);
        this.onClickRemoveFromLoad = this.onClickRemoveFromLoad.bind(this);

        this.onClickMasterDetails = this.onClickMasterDetails.bind(this);

        //Receive drawer
        // this.onClickAdd = this.onClickAdd.bind(this);
        this.onChangeReceiveDrawer = this.onChangeReceiveDrawer.bind(this);
        this.onClickReceive = this.onClickReceive.bind(this);
        this.closeReceiveDrawer = this.closeReceiveDrawer.bind(this);
        this.onClickReceiveDrawerSave = this.onClickReceiveDrawerSave.bind(this);
        this.onClickReceiveButton = this.onClickReceiveButton.bind(this);

        //Customs City
        this.onClickGetCustomsCity = this.onClickGetCustomsCity.bind(this);
        this.onClickGetOnlyCustomsCity = this.onClickGetOnlyCustomsCity.bind(this);

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.HawbData !== this.props.HawbData && this.props.HawbData.length > 0) {
            this.preloadReceiveState()
        }
        if (prevProps.PostHawbSuccessful !== this.props.PostHawbSuccessful && this.props.PostHawbSuccessful) {
            this.onClickReceive(null, this.state.receiveDataRowData, null)
        }

    }

    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });

        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }


    //On Click Details
    onClickDetails(e, rowData, history) {
        try {
            var paramID = rowData["LoadID"]
            history.push({
                pathname: 'loaddetails',
                search: 'loadid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }

    onClickClearCustoms(e) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {


            if (window.confirm('Clear Customs?')) {
                this.props.hawbRecoveryPost(
                    this.props.currentUserEmail,
                    this.props.userSignInData.AuthToken,
                    null,
                    formatNumberForAPI(this.props.HawbData[0].HouseID),
                    null,
                    null,
                    null,
                    null,
                    1,
                    this.props.warehouseSelection,
                    this.props.customerSelection
                )
            }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }

    }


    onClickRemoveFromLoad(rowData, keyColumn) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {


            if (window.confirm('Remove from load?')) {
                this.props.loadRemovePost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData["LoadID"], rowData["HouseID"], rowData["PCS"], rowData["Pallets"], this.props.warehouseSelection, this.props.customerSelection)
            }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }



    onClickMasterDetails(e) {
        try {
            var paramID = this.props.HawbData[0].MasterID
            this.props.history.push({
                pathname: 'masterdetails',
                search: 'masterid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }





    //Receive Master drawer
    onClickReceive(e, rowData, history) {
        // this.props.hawbGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, rowData.MasterID, null, null, null, null, null, null, null, null, null, null, null, null, null)
        // this.preloadReceiveState()
        this.setState({
            ReceiveDict: {},
            unsavedReceiveChanges: false,
            receiveDataRowData: rowData,
            showReceiveDrawer: true,
            ReceiveDrawerHawbID: rowData.HouseID,

            //Add Evolve Data Values
            ReceiveDrawerHouse: rowData.HouseRef,
            ReceiveDrawerNo: rowData.No,
            ReceiveDrawerPMC: rowData.PMC,
            ReceiveDrawerPickupBy: rowData.PickupBy,
            ReceiveDrawerTrips: rowData.Trips,
            ReceiveDrawerArrivalTime: rowData.ArrivalTime,
            ReceiveDrawerLFD: rowData.LFD,
            ReceiveDrawerArrivalAtWarehouse: rowData.ArrivalAtWarehouse,
            ReceiveDrawerDeliveryTime: rowData.DeliveryTime,
            ReceiveDrawerNotes: rowData.Notes
        });
        this.preloadReceiveState()
    }
    onClickReceiveButton(e) {
        // this.props.hawbGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, rowData.MasterID, null, null, null, null, null, null, null, null, null, null, null, null, null)
        // this.preloadReceiveState()
        this.setState({
            ReceiveDict: {},
            unsavedReceiveChanges: false,
            receiveDataRowData: this.props.HawbData[0],
            showReceiveDrawer: true,
            // ReceiveDrawerHawbID: rowData.HouseID,

            //Add Evolve Data Values
            // ReceiveDrawerHouse: rowData.HouseRef,
            // ReceiveDrawerNo: rowData.No,
            // ReceiveDrawerPMC: rowData.PMC,
            // ReceiveDrawerPickupBy: rowData.PickupBy,
            // ReceiveDrawerTrips: rowData.Trips,
            // ReceiveDrawerArrivalTime: rowData.ArrivalTime,
            // ReceiveDrawerLFD: rowData.LFD,
            // ReceiveDrawerArrivalAtWarehouse: rowData.ArrivalAtWarehouse,
            // ReceiveDrawerDeliveryTime: rowData.DeliveryTime,
            // ReceiveDrawerNotes: rowData.Notes
        });
        this.preloadReceiveState()
    }
    preloadReceiveState() {
        var receivedDict = {};
        // var testDict = {}
        for (let k in this.props.HawbData) {
            // receivedDict[this.props.HawbData[k].HouseID] = this.props.HawbData[k].Cartons - this.props.HawbData[k].RecoveredPCS

            // receivedDict[this.props.HawbData[k].HouseID] = { 'Recovered': this.props.HawbData[k].Cartons - this.props.HawbData[k].RecoveredPCS, 'Pallets': null, 'Notes': '', 'Customs': false }
            receivedDict[this.props.HawbData[k].HouseID] = { 'Recovered': null, 'Pallets': null, 'Notes': '', 'Customs': false }

        }
        this.setState({
            unsavedReceiveChanges: true,
            ReceiveDict: receivedDict
        });
    }
    onChangeReceiveDrawer = (event, item, label) => {
        var currentReceiveDict = this.state.ReceiveDict
        var houseId = item.HouseID;

        currentReceiveDict[houseId][label] = event

        this.setState({
            unsavedReceiveChanges: true,
            ReceiveDict: currentReceiveDict
        });

    };
    onClickReceiveDrawerSave(e) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {
            // if (window.confirm('Save Data?')) {
                for (let k in this.state.ReceiveDict) {

                    try { var recoveredNum = this.state.ReceiveDict[k]['Recovered'] }
                    catch (e) { var recoveredNum = 0 }
                    try { var palletNum = this.state.ReceiveDict[k]['Pallets'] }
                    catch (e) { var palletNum = 0 }

                    if ((recoveredNum === null || recoveredNum == 0) && (palletNum === null || palletNum == 0)) {
                    }
                    else {
                        try {
                            this.props.hawbRecoveryPost(
                                this.props.currentUserEmail,
                                this.props.userSignInData.AuthToken,
                                formatNumberForAPI(this.state.receiveDataRowData.MasterID),
                                formatNumberForAPI(k),
                                null,
                                formatNumberForAPI(this.state.ReceiveDict[k]['Recovered']),
                                formatNumberForAPI(this.state.ReceiveDict[k]['Pallets']),
                                formatStringForAPI(this.state.ReceiveDict[k]['Notes']),
                                this.state.ReceiveDict[k]['Customs'],
                                this.props.warehouseSelection,
                                this.props.customerSelection
                            )
                        }
                        catch (e) { }
                    }
                }
                this.setState({
                    unsavedReceiveChanges: false,
                    showReceiveDrawer: false
                });
            // }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }
    closeReceiveDrawer() {
        if (this.state.unsavedReceiveChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setReceiveDrawerToClose()
            }
        }
        else {
            this.setReceiveDrawerToClose()
        }
    }

    setReceiveDrawerToClose() {
        this.setState({
            ReceiveDict: {},

            unsavedReceiveChanges: false,

            showReceiveDrawer: false,

            ReceiveDrawerHawbID: null,
            ReceiveDrawerHouse: null,
            ReceiveDrawerNo: null,
            ReceiveDrawerPMC: null,
            ReceiveDrawerPickupBy: null,
            ReceiveDrawerTrips: null,
            ReceiveDrawerArrivalTime: null,
            ReceiveDrawerLFD: null,
            ReceiveDrawerArrivalAtWarehouse: null,
            ReceiveDrawerDeliveryTime: null,
            ReceiveDrawerNotes: null,
        });
    }


    //Customs city
    onClickGetCustomsCity() {
        var houseref = this.props.HawbData[0].HouseRef
        var masterref = this.props.HawbData[0].Master
        this.props.customsCityAPIMessages(this.props.currentUserEmail, this.props.userSignInData.AuthToken, houseref, masterref, null)
    }
    onClickGetOnlyCustomsCity() {
        var houseref = this.props.HawbData[0].HouseRef
        var masterref = this.props.HawbData[0].Master
        this.props.customsCityAPIMessages(this.props.currentUserEmail, this.props.userSignInData.AuthToken, houseref, masterref, 1)
      }


    render() {

        const { sortColumn, sortType } = this.state;



        if (this.props.isGetCustomsCityAPILoading) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                    <p style={{ marginTop: '20px', fontSize: '20px', color: '#3396E5' }}>Please wait...</p>
                </div>
            );
        }



        let pageHeader;
        let detailsDiv;
        if (this.props.isHawbLoading) {
            detailsDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.HawbData[0]) !== 'undefined' && this.props.HawbData != null) {
            pageHeader = 'Airfreight - House Ref: ' + this.props.HawbData[0].HouseRef
            detailsDiv =
                <div>
                    <Row >

                        <Col md={24} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                {this.props.HawbData[0].CustomsCleared === true ? '' : <Button style={{ float: 'right' }} onClick={(e) => { this.onClickClearCustoms(e) }} >Clear Customs</Button>}

                                <strong>Master:</strong> <Button size="sm" appearance="primary" onClick={(e) => { this.onClickMasterDetails(e) }} >{this.props.HawbData[0].Master}</Button>
                                <br />
                                <br />
                                Status: <strong>{this.props.HawbData[0].HawbStatusName}</strong>
                                <br />
                                Customs Cleared: <strong>{this.props.HawbData[0].CustomsCleared === true ? "True" : "False"}</strong>
                                <br />
                                Origin: <strong>{this.props.HawbData[0].Origin}</strong>
                                <br />
                                Last Discharge Port: <strong>{this.props.HawbData[0].LastDischargePort}</strong>
                                <br />
                                Min ETA Discharge: <strong>{this.props.HawbData[0].MinETADischarge}</strong>
                                <br />

                                Consignee Name: <strong>{this.props.HawbData[0].ConsigneeName}</strong>
                                <br />
                                Deliver To Address: <strong>{this.props.HawbData[0].DeliverToAddress}</strong>
                                <br />
                                Agent Note: <strong>{this.props.HawbData[0].AgentNote}</strong>
                                <br />
                                Orders: <strong>{this.props.HawbData[0].Orders}</strong>
                                <br />

                                DPO: <strong>{this.props.HawbData[0].DPO}</strong>
                                <br />
                                Destination: <strong>{this.props.HawbData[0].DeliveryAddress}</strong>
                                <br />
                                DC Code: <strong>{this.props.HawbData[0].DCCode}</strong>
                                <br />


                            </Panel>
                        </Col>
                    </Row>

                    <Row >
                        <Col md={12} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }} >

                                Received CTNS: <strong>{this.props.HawbData[0].RecoveredPCS}</strong>   <Button style={{ float: 'right' }} onClick={(e) => { this.onClickReceiveButton(e) }} >Receive</Button>
                                <br />
                                In Whse PCS: <strong>{this.props.HawbData[0].InWarehousePCS}</strong>
                                <br />
                                Loaded PCS: <strong>{this.props.HawbData[0].LoadedPCS}</strong>
                                <br />
                                Expected Cartons: <strong>{this.props.HawbData[0].Cartons}</strong>
                                <br />
                                Pallets: <strong>{this.props.HawbData[0].Pallets}</strong>
                                <br />
                                Weight: <strong>{this.props.HawbData[0].Weight}</strong>
                                <br />
                                Chargeable: <strong>{this.props.HawbData[0].Chargeable}</strong>
                                <br />
                                Volume: <strong>{this.props.HawbData[0].Volume}</strong>
                                <br />
                            </Panel>
                        </Col>
                        <Col md={12} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Shipment ETD: <strong>{this.props.HawbData[0].ShipmentETD}</strong>
                                <br />
                                ETA Discharge: <strong>{this.props.HawbData[0].ETADischarge}</strong>
                                <br />
                                Est Cartage Delivery: <strong>{this.props.HawbData[0].EstCartageDelivery}</strong>
                                <br />
                                Actual Cartage Delivery: <strong>{this.props.HawbData[0].ActualCartageDelivery}</strong>
                                <br />
                                NDC Date: <strong>{this.props.HawbData[0].DCRequiredDate}</strong>
                                <br />
                                <br />
                                <br />
                                <br />
                            </Panel>
                        </Col>
                    </Row>
                </div>

        }
        else {
            detailsDiv = <div></div>;
        }


        let currentReceiveMaster;
        try {
            if (typeof (this.props.HawbData[0]) !== 'undefined' && this.props.HawbData != null) {
                currentReceiveMaster = this.props.HawbData[0].Master
            }
            else {
                currentReceiveMaster = ''
            }
        }
        catch (e) {
            currentReceiveMaster = ''
        }


        let receiveDrawer;
        if (this.props.isHawbLoading) {
            receiveDrawer = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        }
        else {
            receiveDrawer = <ReceiveMasterDrawer {...this.props} state={this.state} onChange={this.onChangeReceiveDrawer} confirm={this.onClickReceiveDrawerSave} close={this.closeReceiveDrawer} onClickAddMissing={this.onClickAddMissing} masterLabel={currentReceiveMaster} />
        }




        let customsCityTimeline;
        if (this.props.isGetCustomsCityAPILoading) {

            customsCityTimeline =
                <div>
                    <Panel header={<Row>Customs Events</Row>} bordered style={{ marginBottom: 10 }}>

                        <div align="center">
                            <section className="panel">
                            </section>
                            <Loader
                                type="TailSpin"
                                color="#3396E5"
                                height="50"
                                width="50"
                            />
                        </div>
                    </Panel>
                </div>
        }
        else if (this.props.isCustomsCityMessagesLoading) {
            customsCityTimeline =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }

        else if (typeof (this.props.CustomsCityMessagesData[0]) !== 'undefined' && this.props.CustomsCityMessagesData != null) {

            customsCityTimeline =
                <div>
                    <Panel header={<Row>Customs Events<Button style={{ float: 'right' }} onClick={(e) => { this.onClickGetCustomsCity(e) }} >New Request</Button></Row>} bordered style={{ marginBottom: 10 }}>

                        <Timeline>
                            {this.props.CustomsCityMessagesData.map(item => (
                                <Timeline.Item>
                                    {item.CBPDateTime}  &nbsp;
                                    {item.description}

                                    {item.status != null ? <l><br /> Status:  {item.status} </l> : ' '}
                                </Timeline.Item>
                            ))}
                        </Timeline>
                    </Panel>
                </div>
        }
        else {
            customsCityTimeline =
                <div>
                    <Panel header={<Row>Customs Events<Button style={{ float: 'right' }} onClick={(e) => { this.onClickGetCustomsCity(e) }} >New Request</Button></Row>} bordered style={{ marginBottom: 10 }}>

                    </Panel>
                </div>
        }


        let customsCityHistory;
        if (this.props.isXdockCustomsCityManifestHistoryLoading) {
          customsCityHistory =
            <div align="center">
              <section className="panel">
              </section>
              <Loader
                type="TailSpin"
                color="#3396E5"
                height="50"
                width="50"
              />
            </div>
        }
        else if (typeof (Array.isArray(this.props.XdockCustomsCityManifestHistoryData)) && this.props.XdockCustomsCityManifestHistoryData != null) {
    
          var getCustomsCityAPI = <div></div>
          // Assuming this.props.XdockCustomsCityManifestHistoryData is an array of objects
          const manifestHistoryData = this.props.XdockCustomsCityManifestHistoryData;
    
          // Find the row with the greatest CreatedDT value
          const latestRow = manifestHistoryData.reduce((latest, current) => {
            return new Date(current.CreatedDT) > new Date(latest.CreatedDT) ? current : latest;
          }, manifestHistoryData[0]);
    
          // Check if the GetSuccess value of the latest row is false
          if (latestRow && latestRow.GetSuccess === false) {
            getCustomsCityAPI = <Button style={{ float: 'right' }} onClick={(e) => { this.onClickGetOnlyCustomsCity(e) }} >Get Updates</Button>
            // <Panel header={<Row>Customs Events<Button style={{ float: 'right' }} onClick={(e) => { this.onClickGetCustomsCity(e) }} >Check Updates</Button></Row>} bordered style={{ marginBottom: 10 }}>
          } else {
            getCustomsCityAPI = <div></div>
          }
    
    
    
          customsCityHistory =
            <div>
              <Panel header={<Row>Customs Query History {getCustomsCityAPI} </Row>} bordered style={{ marginBottom: 10 }}>
                <Timeline>
                  {this.props.XdockCustomsCityManifestHistoryData.map(item => (
                    <Timeline.Item>
                      {item.CreatedDT}  &nbsp;
    
                      {item.GetSuccess != null ? <l><br /> Results Returned:  {formatBitToTrueFalse(item.GetSuccess)} </l> : ' '}
                      {item.GetMsg != null ? <l><br /> Response:  {item.GetMsg} </l> : ' '}
    
                    </Timeline.Item>
                  ))}
                </Timeline>
              </Panel>
            </div>
        }
    
    


        let recoveryTimeline;
        if (this.props.isHawbRecoveryLoading) {
            recoveryTimeline =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.HawbRecoveryData[0]) !== 'undefined' && this.props.HawbRecoveryData != null) {
            recoveryTimeline =
                <div>
                    <Panel header="Recovery Events" bordered style={{ marginBottom: 10 }}>
                        <Timeline>
                            {this.props.HawbRecoveryData.map(item => (
                                <Timeline.Item>
                                    {/* <Button size="xs" onClick={(e) => { this.onClickEditRecovery(e, item.HawbRecoveryID) }}> Edit </Button> &nbsp; */}
                                    {item.RecoveryDT}  &nbsp;
                                    {item.EmailAddr}
                                    {item.RecoveredPCS != 0 ? <l><br /> Received CTNS:  {item.RecoveredPCS} </l> : ' '}
                                    {item.Pallets != 0 ? <l><br /> Pallets:  {item.Pallets} </l> : ' '}
                                    {item.CustomsCleared == true ? <l><br /> Customs Cleared: True </l> : ' '}
                                    {item.Notes != null ? <l><br /> Notes:  {item.Notes} </l> : ' '}
                                </Timeline.Item>
                            ))}
                        </Timeline>
                    </Panel>
                </div>
        }


        let loadDiv;
        let rTable;
        if (this.props.isLoadLoading) {
            rTable = <div></div>
            loadDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.LoadData) !== 'undefined' && this.props.LoadData != null) {

            rTable = RTable(
                this.props.LoadData,        // tableData,
                HouseDetailsLoadDataElements,  // DataElements,
                sortColumn,                 // sortColumn,
                sortType,                   // sortType,
                this.props.isLoadLoading,         // loading,
                this.handleSortColumn,      // handleSortColumn,
                this.onClickDetails,        // onClickDetails,
                null,                      // checkColEntry,
                tableIdCol,                 // keyColumn,
                null,                       // checkedKeys,
                null,                       // handleCheckAll,
                null,                       // handleCheck,
                false,                      // boolSelectButton,
                null,     // onClickSelect,
                null,                       // selectButtonText,
                this.props.isMobile,        // isMobile,
                this.props.history,         // history,
                null,                        // highlightRowKey

                true,// editLink,
                'Remove', // editLinkText,
                this.onClickRemoveFromLoad// editLinkOnClick
            )

            loadDiv = <div>

                {(this.props.isMobile == true) ? <div></div> :
                    <div>
                        <Row>
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Load_' + currentDateTime}>
                                <ExcelSheet data={this.props.LoadData} name="Load">
                                    {
                                        HouseDetailsLoadDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }
                                </ExcelSheet>
                            </ExcelFile>
                        </Row>
                    </div>
                }


                {/* {rTable} */}

            </div>
        }
        else {
            rTable = <div></div>
            loadDiv = <div></div>
        }



        let loadTimeline;
        if (this.props.isLoadHawbHistoryLoading) {
            loadTimeline =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.LoadHawbHistoryData[0]) !== 'undefined' && this.props.LoadHawbHistoryData != null) {
            loadTimeline =
                <div>
                    <Panel header="Load Events" bordered style={{ marginBottom: 10 }}>
                        <Timeline>
                            {this.props.LoadHawbHistoryData.map(item => (
                                <Timeline.Item>
                                    {item.LoadHawbDT}  &nbsp;
                                    {item.EmailAddr}
                                    {item.Carrier != null ? <l><br /> Carrier:  {item.Carrier} </l> : ' '}
                                    {item.Trailer != null ? <l><br /> Trailer:  {item.Trailer} </l> : ' '}
                                    {item.PCS != 0 ? <l><br /> PCS:  {item.PCS} </l> : ' '}
                                    {item.Pallets != 0 ? <l><br /> Pallets:  {item.Pallets} </l> : ' '}
                                    {item.Notes != null ? <l><br /> Notes:  {item.Notes} </l> : ' '}
                                </Timeline.Item>
                            ))}
                        </Timeline>
                    </Panel>
                </div>
        }


        return (
            <div>
                <h3>
                    {pageHeader}
                </h3>

                <PanelGroup>

                    {detailsDiv}

                    {/* {poProductDetailsDiv} */}

                </PanelGroup>


                {customsCityTimeline}

                {customsCityHistory}


                <Panel header="Load List" bordered style={{ marginBottom: 10 }}>
                    {loadDiv}
                    {rTable}
                </Panel>



                {recoveryTimeline}

                {loadTimeline}

                {receiveDrawer}

            </div>
        );

    }
}

export default withRouter(HouseDetails);

