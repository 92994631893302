import React, {
    PureComponent
} from 'react';
import AnimatedView from '../../../../components/componentsGeneral/AnimatedView/AnimatedView';
import ReportingContainer from '../../../../components/componentsInventoryManagement/_Lot_Tracking_Components/Reporting/ReportingContainer';
import { Message } from 'rsuite';
import { lotTrackingWarehouseIDs } from '../../../../app/appConfig';

class invManLotReportingView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    
    componentDidMount() {

    }

    render() {

        if (!lotTrackingWarehouseIDs.includes(this.props.warehouseSelection) && !lotTrackingWarehouseIDs.includes("All")) {
            return <AnimatedView><Message style={{ marginTop: '25px' }} type="info" description="Please only select Lot Tracking warehouses" /></AnimatedView>;
        }

        // if (typeof this.props.customerSelection !== 'number') {
        //     return <AnimatedView><Message style={{ marginTop: '25px' }} type="info" description="Please select one customer" /></AnimatedView>;
        // }

        return (
            <AnimatedView>
                <ReportingContainer isMobile={this.props.isMobile} warehouseSelection={this.props.warehouseSelection} customerSelection={this.props.customerSelection}/>
            </AnimatedView>

        );
    }
}

export default invManLotReportingView;
