import { connect } from 'react-redux';
import React, { Component } from 'react';
import LoadDetails from './LoadDetails'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../redux/actions/vasActions';
import { queryStringParse, formatStringForAPI } from '../../../resources/logic/functions';
import * as ctrlTwrActions from '../../../redux/actions/ctrlTwrActions';


const mapStateToProps = store =>
({



    isFileDownloadLoading: store.ctrlTwrReducer.isFileDownloadLoading,
    FileDownloadData: store.ctrlTwrReducer.FileDownloadData,

    currentUserEmail:                           store.userServicesReducer.currentUserEmail,
    userSignInData:                             store.userServicesReducer.userSignInData,

    isLoadLoading:                              store.vasReducer.isLoadLoading,
    LoadData:                                   store.vasReducer.LoadData,

    isLoadHawbLoading:                                  store.vasReducer.isLoadHawbLoading,
    LoadHawbData:                                       store.vasReducer.LoadHawbData,

    PostLoadSuccessful:                                 store.vasReducer.PostLoadSuccessful,

    PostLoadRemoveSuccessful:                            store.vasReducer.PostLoadRemoveSuccessful,

    PostLoadEditSuccessful:                              store.vasReducer.PostLoadEditSuccessful,

    isLoadHawbHistoryLoading:                            store.vasReducer.isLoadHawbHistoryLoading,
    LoadHawbHistoryData:                                 store.vasReducer.LoadHawbHistoryData,

    isHawbLoading: store.vasReducer.isHawbLoading,
    HawbData: store.vasReducer.HawbData,

    isXdockLoadFileUploadLoading: store.vasReducer.isXdockLoadFileUploadLoading,
    XdockLoadFileUploadData: store.vasReducer.XdockLoadFileUploadData,

    XdockLoadFileUploadPostSuccessful: store.vasReducer.XdockLoadFileUploadPostSuccessful,



    isXdockLoadPalletCartonLoading: store.vasReducer.isXdockLoadPalletCartonLoading,
    XdockLoadPalletCartonData: store.vasReducer.XdockLoadPalletCartonData,


    XDockLoadPalletCartonPostSuccessful: store.vasReducer.XDockLoadPalletCartonPostSuccessful,
    newPalletIDCarton: store.vasReducer.newPalletIDCarton,

    XDockLoadPalletPostSuccessful: store.vasReducer.XDockLoadPalletPostSuccessful,
    newPalletID: store.vasReducer.newPalletID,


    isXdockLoadPalletLoading: store.vasReducer.isXdockLoadPalletLoading,
    XdockLoadPalletData: store.vasReducer.XdockLoadPalletData,

});

const mapDispatchToProps = dispatch =>
({

    downloadFileFromS3: (_email, _authToken, _ObjectName, _FilenameToSave, _S3Bucket) => {
        dispatch(ctrlTwrActions.downloadFileFromS3(_email, _authToken, _ObjectName, _FilenameToSave, _S3Bucket))
    },

    loadGet:( _email, _authToken, _LoadID, _HouseID,_MasterID,_PickupDateStart,_PickupDateEnd,_DeliveryDateStart,_DeliveryDateEnd,_LoadStatusID,_Carrier,_BOL,_Trailer,_Seal,_ConsigneeID,_DestinationID, _WarehouseID, _CustomerID ) =>
    {
        dispatch( actions.loadGet( _email, _authToken, _LoadID, _HouseID,_MasterID,_PickupDateStart,_PickupDateEnd,_DeliveryDateStart,_DeliveryDateEnd,_LoadStatusID,_Carrier,_BOL,_Trailer,_Seal,_ConsigneeID,_DestinationID, _WarehouseID, _CustomerID ) )
    },
    loadHawbGet:( _email, _authToken, _LoadID, _HouseID, _MasterID, _WarehouseID, _CustomerID ) =>
    {
        dispatch( actions.loadHawbGet( _email, _authToken, _LoadID, _HouseID, _MasterID, _WarehouseID, _CustomerID ) )
    },

    loadPost:( _email, _authToken, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalPCS, _TotalPallets, _LoadStatusID, _LoadHawbData, _HouseID, _PCS, _Pallets, _WarehouseID, _CustomerID, _MC, _DOT, _Driver, _Truck, _TruckPlate, _TrailerPlate, _Notes  ) =>
    {
        dispatch( actions.loadPost( _email, _authToken, _LoadID, _PickupDate, _DeliveryDate, _Carrier, _BOL, _Trailer, _Seal, _TotalPCS, _TotalPallets, _LoadStatusID, _LoadHawbData, _HouseID, _PCS, _Pallets, _WarehouseID, _CustomerID, _MC, _DOT, _Driver, _Truck, _TruckPlate, _TrailerPlate, _Notes  ) )
    },

    loadRemovePost:( _email, _authToken, _LoadID, _HouseID, _PCS, _Pallets , _WarehouseID, _CustomerID) =>
    {
        dispatch( actions.loadRemovePost( _email, _authToken, _LoadID, _HouseID, _PCS, _Pallets , _WarehouseID, _CustomerID) )
    },

    loadEditPost:( _email, _authToken, _LoadID, _HouseID, _PCS, _Pallets , _WarehouseID, _CustomerID) =>
        {
            dispatch( actions.loadEditPost( _email, _authToken, _LoadID, _HouseID, _PCS, _Pallets , _WarehouseID, _CustomerID) )
        },
    


    loadHawbHistoryGet:( _email, _authToken, _HouseID,  _LoadID, _WarehouseID, _CustomerID ) =>
    {
        dispatch( actions.loadHawbHistoryGet( _email, _authToken, _HouseID,  _LoadID, _WarehouseID, _CustomerID ) )
    },

    hawbGet: (_email, _authToken, _HouseID, _HouseRef, _MasterID, _Master, _ShipmentETDStart, _ShipmentETDEnd, _Origin, _LastDischargePort, _ETADischargeStart, _ETADischargeEnd, _EstCartageDeliveryStart, _EstCartageDeliveryEnd, _ActualCartageDeliveryStart, _ActualCartageDeliveryEnd, _HawbStatusID, _ConsigneeID, _Destination, _WarehouseID, _WarehousePort, _OrderNo, _CustomerID, _CustomsCategoryID) => {
        dispatch(actions.hawbGet(_email, _authToken, _HouseID, _HouseRef, _MasterID, _Master, _ShipmentETDStart, _ShipmentETDEnd, _Origin, _LastDischargePort, _ETADischargeStart, _ETADischargeEnd, _EstCartageDeliveryStart, _EstCartageDeliveryEnd, _ActualCartageDeliveryStart, _ActualCartageDeliveryEnd, _HawbStatusID, _ConsigneeID, _Destination, _WarehouseID, _WarehousePort, _OrderNo, _CustomerID, _CustomsCategoryID))
    },



    xDockLoadFileUploadGet: (_email, _authToken, _CustomerID, _FileUploadID, _LoadID, _FileName, _S3FileName, _FileType, _FileDelete) => {
        dispatch(actions.xDockLoadFileUploadGet(_email, _authToken, _CustomerID, _FileUploadID, _LoadID, _FileName, _S3FileName, _FileType, _FileDelete))
    },
    xDockLoadFileUploadPost: (_email, _authToken, _CustomerID, _FileUploadID, _LoadID, _FileName, _S3FileName, _FileType, _FileDelete) => {
        dispatch(actions.xDockLoadFileUploadPost(_email, _authToken, _CustomerID, _FileUploadID, _LoadID, _FileName, _S3FileName, _FileType, _FileDelete))
    },


    xDockLoadPalletCartonGet: (_email, _authToken, _LoadID, _LoadPalletID, _LoadPalletCartonID) => {
        dispatch(actions.xDockLoadPalletCartonGet(_email, _authToken, _LoadID, _LoadPalletID, _LoadPalletCartonID))
    },

    xDockLoadPalletCartonPost: (_email, _authToken, _LoadPalletCartonID, _LoadPalletID, _LoadID, _HouseID, _CartonID, _CartonQty, _Delete) => {
        dispatch(actions.xDockLoadPalletCartonPost(_email, _authToken, _LoadPalletCartonID, _LoadPalletID, _LoadID, _HouseID, _CartonID, _CartonQty, _Delete))
    },


    xDockLoadPalletPost: (_email, _authToken, _LoadPalletID, _LoadID, _HouseID, _PalletSeq, _Delete) => {
        dispatch(actions.xDockLoadPalletPost(_email, _authToken, _LoadPalletID, _LoadID, _HouseID, _PalletSeq, _Delete))
    },


    xDockLoadPalletGet: (_email, _authToken, _LoadID, _LoadPalletID) => {
        dispatch(actions.xDockLoadPalletGet(_email, _authToken, _LoadID, _LoadPalletID))
    },


});

class LoadDetailsContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.handleUrlParam();
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.handleUrlParam();
        }
        
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
        if (prevProps.PostLoadRemoveSuccessful !== this.props.PostLoadRemoveSuccessful && this.props.PostLoadRemoveSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }

        if (prevProps.PostLoadEditSuccessful !== this.props.PostLoadEditSuccessful && this.props.PostLoadEditSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }


        

        if (prevProps.PostLoadSuccessful !== this.props.PostLoadSuccessful && this.props.PostLoadSuccessful) {
            setTimeout(() => {
                this.handleUrlParam()
            }, 1000);
        }



        if (prevProps.XDockLoadPalletPostSuccessful !== this.props.XDockLoadPalletPostSuccessful && this.props.XDockLoadPalletPostSuccessful) {
            this.handleUrlParam()
        }

        

        if (prevProps.XDockLoadPalletCartonPostSuccessful !== this.props.XDockLoadPalletCartonPostSuccessful && this.props.XDockLoadPalletCartonPostSuccessful) {
            this.handleUrlParam()
        }




        if (prevProps.XdockLoadFileUploadPostSuccessful !== this.props.XdockLoadFileUploadPostSuccessful && this.props.XdockLoadFileUploadPostSuccessful) {
            const values = queryString.parse(this.props.location.search)
            try {
                var paramLoadID = queryStringParse(values.loadid)
            }
            catch (e) {
                var paramLoadID = null
            }
            this.props.xDockLoadFileUploadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.customerSelection, null, paramLoadID, null, null, null, 0)
        }
    }

   

    handleUrlParam = () => {
        const values = queryString.parse(this.props.location.search)

        try{
            var paramLoadID = queryStringParse( values.loadid )
        }
        catch(e){
            var paramLoadID = null
        }
        // try{
        //     var paramHouseRef = queryStringParse( values.houseref )
        // }
        // catch(e){
        //     var paramHouseRef = null
        // }
        // this.props.hawbGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramHouseID, paramHouseRef, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null );
        // this.props.hawbRecoveryGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramHouseID, paramHouseRef );
        this.props.loadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramLoadID, null, null, null, null, null, null, null, null, null, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection)
        this.props.loadHawbGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramLoadID, null, null, this.props.warehouseSelection, this.props.customerSelection)
        this.props.loadHawbHistoryGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, paramLoadID, this.props.warehouseSelection, this.props.customerSelection)


        this.props.xDockLoadPalletCartonGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramLoadID, null, null)

        this.props.xDockLoadPalletGet( this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramLoadID, null)



        this.props.hawbGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            1,
            null,
            null,
            this.props.warehouseSelection,
            null, 
            null,
            this.props.customerSelection, 
            null
            );



            this.props.xDockLoadFileUploadGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.customerSelection, null, paramLoadID, null, null, null, 0)
   
    }



    render() {

        return (

            <LoadDetails
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}
                warehouseSelection={this.props.warehouseSelection}
                customerSelection={this.props.customerSelection}

                isLoadLoading={this.props.isLoadLoading}
                LoadData={this.props.LoadData}

                isLoadHawbLoading={this.props.isLoadHawbLoading}
                LoadHawbData={this.props.LoadHawbData}

                loadPost={this.props.loadPost}
                PostLoadSuccessful={this.props.PostLoadSuccessful}

                loadRemovePost={this.props.loadRemovePost}
                PostLoadRemoveSuccessful={this.props.PostLoadRemoveSuccessful}

                loadEditPost={this.props.loadEditPost}
                PostLoadEditSuccessful={this.props.PostLoadEditSuccessful}

                isLoadHawbHistoryLoading={this.props.isLoadHawbHistoryLoading}
                LoadHawbHistoryData={this.props.LoadHawbHistoryData}

                isHawbLoading={this.props.isHawbLoading}
                HawbData={this.props.HawbData}
                hawbGet={this.props.hawbGet}



                downloadFileFromS3={this.props.downloadFileFromS3}
                isFileDownloadLoading={this.props.isFileDownloadLoading}
                FileDownloadData={this.props.FileDownloadData}

                isXdockLoadFileUploadLoading={this.props.isXdockLoadFileUploadLoading}
                XdockLoadFileUploadData={this.props.XdockLoadFileUploadData}
                XdockLoadFileUploadPostSuccessful={this.props.XdockLoadFileUploadPostSuccessful}



                xDockLoadFileUploadGet={this.props.xDockLoadFileUploadGet}
                xDockLoadFileUploadPost={this.props.xDockLoadFileUploadPost}


                xDockLoadPalletCartonGet={this.props.xDockLoadPalletCartonGet}
                isXdockLoadPalletCartonLoading={this.props.isXdockLoadPalletCartonLoading}
                XdockLoadPalletCartonData={this.props.XdockLoadPalletCartonData}


                XDockLoadPalletCartonPostSuccessful={this.props.XDockLoadPalletCartonPostSuccessful}
                newPalletIDCarton={this.props.newPalletIDCarton}
                xDockLoadPalletCartonPost={this.props.xDockLoadPalletCartonPost}


                XDockLoadPalletPostSuccessful={this.props.XDockLoadPalletPostSuccessful}
                newPalletID={this.props.newPalletID}
                xDockLoadPalletPost={this.props.xDockLoadPalletPost}



                isXdockLoadPalletLoading={this.props.isXdockLoadPalletLoading}
                XdockLoadPalletData={this.props.XdockLoadPalletData}
                xDockLoadPalletGet={this.props.xDockLoadPalletGet}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoadDetailsContainer));




